import AddressAutocompleteController from './address_autocomplete_controller';
import AddressAutocompleteV2Controller from './address_autocomplete_v2_controller';
import AddressBookController from './addressbook_controller';
import AddressController from './address_controller';
import { Application } from '@hotwired/stimulus';
import AutocompleteController from './autocomplete_controller';
import BoardingController from './boarding_controller';
import BulkOrder__AddressController from './bulk_order/address_controller';
import BulkOrder__CatalogController from './bulk_order/catalog_controller';
import BulkOrder__ConfigurationSearchController from './bulk_order/configuration_search_controller';
import BulkOrder__RecipientsController from './bulk_order/recipients_controller';
import BulkOrder__TradeInController from './bulk_order/trade_in_controller';
import { BarController as ChartsBarController } from './charts/bar.controller';
import { PieController as ChartsPieController } from './charts/pie.controller';
import { CnTableClearColumnsController } from './cn_table_clear_columns.controller';
import CopyInputClipboardController from './copy_input_clipboard_controller';
import CopyInputController from './copy_input_controller';
import { DateFilterController } from './date_filter.controller';
import DestroyFlagController from './destroy_flag_controller';
import DisableOnClickController from './disable_on_click_controller';
import DropdownController from './dropdown_controller';
import DynamicListController from './dynamic_list_controller';
import FileDragAndDropController from './file_drag_and_drop_controller';
import FileInputController from './file_input_controller';
import HeaderController from './header_controller';
import Leasing__DiscountController from './leasing/discount_controller';
import Leasing__DlmPriceController from './leasing/dlm_price_controller';
import Leasing__DownloadController from './leasing/download_controller';
import Leasing__ItemController from './leasing/item_controller';
import Leasing__OptionSelectController from './leasing/option_select_controller';
import Leasing__SendToLessorController from './leasing/send_to_lessor_controller';
import LogoutController from './logout_controller';
import MultiStepModalController from './multi_step_modal_controller';
import NotificationsController from './notifications_controller';
import OddController from './odd_controller';
import Order__BanSelectionController from './order/ban_selection_controller';
import Order__CatalogsController from './order/catalogs_controller';
import Order__DebitorSelectionController from './order/debitor_selection_controller';
import PaginationController from './pagination_controller';
import RadioSelectController from './radio_select_controller';
import RedirectOnConnectController from './redirect_on_connect_controller';
import Remarketing__DeviceController from './remarketing/device_controller';
import Remarketing__DeviceListController from './remarketing/device_list_controller';
import SaveInputController from './save_input_controller';
import SelectController from './select_controller';
import SessionStorageController from './sessions_storage_controller';
import SortingController from './sorting_controller';
import SuperAdmin__ArticlePictureController from './super_admin/article_picture_controller';
import SuperAdmin__ArticlePicturesController from './super_admin/article_pictures_controller';
import SuperAdmin__ArticlesModalController from './super_admin/articles_modal_controller';
import SuperAdmin__CompanyCatalogController from './super_admin/company_catalog_controller';
import SuperAdmin__CompanyLogoController from './super_admin/company_logo_controller';
import SuperAdmin__ContractPositionSearchController from './super_admin/contract_position_search_controller';
import SuperAdmin__ErpArticlesModalController from './super_admin/erp_articles_modal_controller';
import { HsbAccessoriesController as SuperAdmin__HsbAccessoriesController } from './super_admin/hsb_accessories.controller';
import SuperAdmin__PersonAttributesController from './super_admin/person_attributes_controller';
import SuperAdmin__ProductBundlesModalController from './super_admin/product_bundles_modal_controller';
import SuperAdmin__SSOGroupRoleMapsController from './super_admin/sso_group_role_maps_controller';
import SuperAdmin__ThemeController from './super_admin/theme_controller';
import SuperAdmin__VodafoneCatalogItemsModalController from './super_admin/vodafone_catalog_items_modal_controller';
import TableRowController from './table_row_controller';
import TextlimitController from './textlimit_controller';
import V2__Order__ArticleBundlesController from './v2/order/article_bundles_controller';
import { V2__Order__ArticleContainerController } from './v2/order/article_container_controller';
import V2__Order__ArticlesController from './v2/order/articles_controller';
import V2__Order__CartController from './v2/order/cart_controller';
import V2__Order__Checkout__BansController from './v2/order/checkouts/bans_controller';
import V2__Order__Checkout__DebitorsController from './v2/order/checkouts/debitors_controller';
import V2__Order__HsbAccessoriesController from './v2/order/hsb_accessories_controller';
import { HsbComputersController as V2__Order__HsbComputersController } from './v2/order/hsb_computers_controller';
import V2__Order__HsbDevicesController from './v2/order/hsb_devices_controller';
import V2__Order__TradeInController from './v2/order/trade_in_controller';
import V2__Order__VodafoneItemsController from './v2/order/vodafone_items_controller';
import V3__PeopleAssetsController from './v3/people/assets_controller';
import V3__PeopleTabsController from './v3/people/tabs_controller';
import { WidgetController as V4__Dashboard__WidgerController } from './v4/dashboard/widget.controller';

const application = Application.start();
application.register('address-autocomplete', AddressAutocompleteController);
application.register('address-autocomplete-v2', AddressAutocompleteV2Controller);
application.register('addressbook', AddressBookController);
application.register('address', AddressController);
application.register('autocomplete', AutocompleteController);
application.register('boarding', BoardingController);
application.register('bulk-order--address', BulkOrder__AddressController);
application.register('bulk-order--catalog', BulkOrder__CatalogController);
application.register('bulk-order--configuration-search', BulkOrder__ConfigurationSearchController);
application.register('bulk-order--recipients', BulkOrder__RecipientsController);
application.register('bulk-order--trade-in', BulkOrder__TradeInController);
application.register('charts--bar', ChartsBarController);
application.register('charts--pie', ChartsPieController);
application.register('cn-table-clear-columns', CnTableClearColumnsController);
application.register('copy-input-clipboard', CopyInputClipboardController);
application.register('copy-input', CopyInputController);
application.register('date-filter', DateFilterController);
application.register('destroy-flag', DestroyFlagController);
application.register('disable-on-click', DisableOnClickController);
application.register('dropdown', DropdownController);
application.register('dynamic-list', DynamicListController);
application.register('file-drag-and-drop', FileDragAndDropController);
application.register('file-input', FileInputController);
application.register('header', HeaderController);
application.register('leasing--send-to-lessor', Leasing__SendToLessorController);
application.register('logout', LogoutController);
application.register('multi-step-modal', MultiStepModalController);
application.register('notifications', NotificationsController);
application.register('odd', OddController);
application.register('order--ban-selection', Order__BanSelectionController);
application.register('order--catalogs', Order__CatalogsController);
application.register('order--debitor-selection', Order__DebitorSelectionController);
application.register('pagination', PaginationController);
application.register('radio-select', RadioSelectController);
application.register('remarketing--device', Remarketing__DeviceController);
application.register('remarketing--device-list', Remarketing__DeviceListController);
application.register('save-input', SaveInputController);
application.register('select', SelectController);
application.register('session-storage', SessionStorageController);
application.register('sorting', SortingController);
application.register('super-admin--article-picture', SuperAdmin__ArticlePictureController);
application.register('super-admin--article-pictures', SuperAdmin__ArticlePicturesController);
application.register('super-admin--articles-modal', SuperAdmin__ArticlesModalController);
application.register('super-admin--company-catalog', SuperAdmin__CompanyCatalogController);
application.register('super-admin--company-logo', SuperAdmin__CompanyLogoController);
application.register('super-admin--contract-position-search', SuperAdmin__ContractPositionSearchController);
application.register('super-admin--sso-group-role-maps', SuperAdmin__SSOGroupRoleMapsController);
application.register('super-admin--erp-articles-modal', SuperAdmin__ErpArticlesModalController);
application.register('super-admin--hsb-accessories', SuperAdmin__HsbAccessoriesController);
application.register('super-admin--person-attributes', SuperAdmin__PersonAttributesController);
application.register('super-admin--product-bundles-modal', SuperAdmin__ProductBundlesModalController);
application.register('super-admin--theme', SuperAdmin__ThemeController);
application.register('super-admin--vodafone-catalog-items-modal', SuperAdmin__VodafoneCatalogItemsModalController);
application.register('table-row', TableRowController);
application.register('textlimit', TextlimitController);
application.register('v2--order--article-bundles', V2__Order__ArticleBundlesController);
application.register('v2--order--article-container', V2__Order__ArticleContainerController);
application.register('v2--order--articles', V2__Order__ArticlesController);
application.register('v2--order--cart', V2__Order__CartController);
application.register('v2--order--checkout--bans', V2__Order__Checkout__BansController);
application.register('v2--order--checkout--debitors', V2__Order__Checkout__DebitorsController);
application.register('v2--order--hsb-accessories', V2__Order__HsbAccessoriesController);
application.register('v2--order--hsb-computers', V2__Order__HsbComputersController);
application.register('v2--order--hsb-devices', V2__Order__HsbDevicesController);
application.register('v2--order--trade-in', V2__Order__TradeInController);
application.register('v2--order--vodafone-items', V2__Order__VodafoneItemsController);
application.register('v3--people--assets', V3__PeopleAssetsController);
application.register('v3--people--tabs', V3__PeopleTabsController);
application.register('v4--dashboard--widget', V4__Dashboard__WidgerController);
application.register('leasing--item', Leasing__ItemController);
application.register('leasing--download', Leasing__DownloadController);
application.register('leasing--option-select', Leasing__OptionSelectController);
application.register('leasing--dlm-price', Leasing__DlmPriceController);
application.register('leasing--discount', Leasing__DiscountController);
application.register('redirect-on-connect', RedirectOnConnectController);
