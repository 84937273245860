import { CnCheckbox, CnSelect } from '@creative-network/hencke-layout';
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

interface Item {
  variationId: string;
  tariffId: string;
  paymentOptionId: string;
  subvention: boolean;
  preExtensionAccepted?: boolean;
}

export default class extends Controller {
  private currentItem: Item;

  public connect() {
    this.updatePrice();
    this.element.addEventListener('updatePrice', this.updatePrice.bind(this));
  }

  public updatePrice() {
    this.updateCurrentItem();

    fetch(`${location.pathname}/update_price?${this.updatePriceParams()}`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        history.replaceState(history.state, '', `${location.pathname}?${this.updatePriceParams()}`);
      });
  }

  private updatePriceParams() {
    const urlParams = new URLSearchParams();

    urlParams.set('variation_id', this.currentItem.variationId);
    urlParams.set('tariff_id', this.currentItem.tariffId);
    urlParams.set('payment_option_id', this.currentItem.paymentOptionId);
    urlParams.set('subvention', this.currentItem.subvention.toString());
    urlParams.set('pre_extension_accepted', this.currentItem.preExtensionAccepted?.toString());

    return urlParams;
  }

  private updateCurrentItem() {
    const selectedVariation = document.querySelector('.colors a.active');
    const selectedTariff = document.querySelector<CnSelect>('cn-select[name="v2_order_item[tariff]"]');
    const selectedPaymentOption = document.querySelector<CnSelect>(
      'cn-select[name="v2_order_item[v2_order_payment_option_id]"]',
    );
    const subvention = document.querySelector<CnCheckbox>('cn-checkbox[name="v2_order_item[subvention]"]').checked;
    const preExtensionAccepted = document.querySelector<CnCheckbox>(
      'cn-checkbox[name="v2_order_item[pre_extension_accepted]"]',
    )?.checked;

    this.currentItem = {
      variationId: selectedVariation.id,
      tariffId: selectedTariff?.value[0] || '',
      paymentOptionId: selectedPaymentOption.value[0],
      subvention,
      preExtensionAccepted,
    };
  }
}
